import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'

@Component({
  selector: 'mw-toggle',
  standalone: true,
  imports: [MatSlideToggleModule],
  templateUrl: './mw-toggle.component.html',
  styleUrl: './mw-toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MwToggleComponent {
  @Input() checked = false;
  @Output() readonly changed = new EventEmitter<boolean>();

  onChange(value: boolean): void {
    this.changed.emit(value);
  }
}
